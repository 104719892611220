import { Injectable } from '@angular/core';
import { NgGapiClientConfig } from 'ng-gapi';

const gapi: any = window.gapi;
export const gapiClientConfig: NgGapiClientConfig = {
    client_id: '117943661390-lefo445ao3983f2cmghf3fpd0jlh6ka3.apps.googleusercontent.com',
    discoveryDocs: ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"],
    scope: [
        "https://www.googleapis.com/auth/drive",
        "https://www.googleapis.com/auth/spreadsheets",
        "https://www.googleapis.com/auth/drive",
        "https://www.googleapis.com/auth/calendar"
    ].join(" ")
};

@Injectable({
    providedIn: 'root'
})
export class GapiService {

    /**
     * Insert event from eventInformation
     */
    async createCalendarEvents(eventInformation: any) {
        await gapi.load('client:auth2', () => {
            gapi.client.init(gapiClientConfig).then(() => {
                if (!gapi.auth2.getAuthInstance().isSignedIn.get()) gapi.auth2.getAuthInstance().signIn();
                gapi.client.load('calendar', 'v3', () => {
                    gapi.client.calendar.events.insert({
                        'calendarId': 'primary',
                        'resource': eventInformation
                    }).execute((event: any) => {
                        // console.log(JSON.stringify('Event created: ' + event.htmlLink, null, 2));
                    });
                });
            });
        });
    }

    /**
     * Print the summary and start datetime/date of the next ten events in
     * the authorized user's calendar. If no events are found an
     * appropriate message is printed.
     */
    async listUpcomingEvents(upComingEvents: any) {
        await gapi.load('client:auth2', () => {
            gapi.client.init(gapiClientConfig).then(() => {
                if (!gapi.auth2.getAuthInstance().isSignedIn.get()) gapi.auth2.getAuthInstance().signIn();
                gapi.client.load('calendar', 'v3', () => {
                    gapi.client.calendar.events.list(upComingEvents).then((response: any) => {
                        return response.result.items;
                    });
                });
            });
        });
    }

}
