import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA, MatLegacyDialogRef } from '@angular/material/legacy-dialog';
import { mapId, prepareRequest, safeMapId, sortBy, includes, deepCopy } from '@proman/utils';
import { Entity } from '@proman/services/entity.service';
import { QueryExpressionService } from '@proman/services/query-expression.service';
import { ModelItemInterface, ModelService } from '@proman/services/model.service';
import { PromanStateService } from '@frontend/shared/services/proman-state.service';
import { Customer, SaleEvent, SaleOpportunity } from '@proman/interfaces/entity-interfaces';
import { GapiService } from '../../gapi/services/gapi.service';
import { Store } from '@ngrx/store';
import { CurrUser } from '@proman/interfaces/object-interfaces';
import { getCurrUser } from '@proman/store/curr-user';
import { CreateOnlineMeetingInterface } from '@proman/resources/create_online_meeting';

@Component({
    selector: 'pm-sale-template-dialog',
    template: `
        <pro-dialog-title [title]="isCreate ? 'create' : 'edit'"></pro-dialog-title>
        <div mat-dialog-content>
            <pm-txt [value]="saleEvent.name"
                    [config]="{ label: 'name' }"
                    (onChange)="model.update('name', $event)"></pm-txt>
            <pro-autoc [value]="saleEvent.customer"
                       [config]="{ entity: 'customer', label: 'customer' }"
                       (onChange)="model.updateAssociation('customer', $event)"
                       [disabled]="!!customer || !!(data.saleOpportunity && saleEvent.customer)"
            ></pro-autoc>
            <pro-autoc [value]="saleEvent.saleEventType"
                       [config]="{ entity: 'sale_event_type', label: 'type', required: true }"
                       (onChange)="model.updateAssociation('saleEventType', $event)"></pro-autoc>
            <div *ngIf="saleEvent.saleEventType?.name === 'Meeting online'">
                <img *ngFor="let item of onlineEventItems"
                     [src]="item.img"
                     class="Clickable"
                     style="padding: 16px"
                     alt="meeting_{{ item.name }}"
                     (click)="handleOnlineEvent(item.name)"/>
            </div>
            <pro-datepicker [value]="saleEvent.date"
                           (onChange)="model.update('date', $event)"
                           [config]="{ label: 'date' }"></pro-datepicker>
            <pm-time-interval [value]="saleEvent.duration"
                              [config]="{ label: 'duration', visibility: { days: true, hours: true, minutes: true, seconds: false }  }"
                              (onChange)="model.update('duration', $event)"></pm-time-interval>
          <pm-txt [value]="saleEvent.location"
                  [config]="{ label: 'location' }"
                  (onChange)="model.update('location', $event)"></pm-txt>
            <pm-txt [config]="{ label: 'goals', type: 'textarea' }"
                    [value]="saleEvent.goals"
                    (onChange)="model.update('goals', $event)"></pm-txt>
            <pm-txt [config]="{ label: 'summary', type: 'textarea' }"
                    [value]="saleEvent.summary"
                    (onChange)="model.update('summary', $event)"></pm-txt>
            <pm-txt [config]="{ label: 'resume', type: 'textarea' }"
                    [value]="saleEvent.resume"
                    (onChange)="model.update('resume', $event)"></pm-txt>
            <pm-contacts-manager [config]="{ label: 'participants', key: 'participants', mainEntity: 'sale_event', mainParams: { id: saleEvent && saleEvent.id }, isCreate: isCreate }"
                                 [onSearch]="searchParticipant"
                                 [value]="saleEvent.participants"></pm-contacts-manager>
        </div>
        <pro-dialog-actions (callback)="create()"
                           [isCallbackDisabled]="!canCreate"
                           [isCallbackHidden]="!isCreate"
                           [variant]="isCreate ? 'create' : ''">
            <pro-btn *ngIf="!saleEvent.isConducted && saleEvent.id" label="done" theme="accent" (onClick)="model.update('isConducted', true)"></pro-btn>
        </pro-dialog-actions>
    `,
    styles: [`
    img {
        height: 40px;
        margin: 0 16px;
    }
    `]
})

export class SaleEventDialogComponent implements OnInit {
    saleEvent: SaleEvent;
    customer: Customer;
    isCreate: boolean;
    canCreate: boolean;
    model: ModelItemInterface;
    saleOpportunity: SaleOpportunity;
    onlineEventItems: Array<{ name: string; img: string }>;
    currUser: CurrUser;

    constructor(
        @Inject(MAT_LEGACY_DIALOG_DATA) public data: { customer?: Customer; saleOpportunity?: SaleOpportunity; item: SaleEvent },
        private Entity: Entity,
        private Model: ModelService,
        private PromanState: PromanStateService,
        private QueryExpression: QueryExpressionService,
        private store: Store,
        private dialogRef: MatLegacyDialogRef<SaleEventDialogComponent>,
        private Gapi: GapiService,
    ) {
        this.saleOpportunity = this.data.saleOpportunity;
        this.customer = this.data.customer || this.saleOpportunity && this.saleOpportunity.customer;

        this.onlineEventItems = ['meet', 'zoom', 'teams', 'voov'].map((type) => ({
            name: type,
            img: `assets/images/${type}.${'png'}`
            })
        );

        this.store.select(getCurrUser)
            .subscribe((value) => this.currUser = value);
    }

    ngOnInit() {
        this.isCreate = !(this.data.item && this.data.item.id);

        if (this.isCreate) {
            this.saleEvent = {
                participants: this.saleOpportunity && deepCopy(this.saleOpportunity.contacts) || [],
                customer: this.saleOpportunity && this.saleOpportunity.customer || this.customer || null,

            } as SaleEvent;

            if (this.saleOpportunity) {
                this.saleEvent.saleOpportunity = this.saleOpportunity;
            }

        } else {
            this.saleEvent = this.data.item;

        }

        this.model = this.Model.get(this.saleEvent, 'sale_event');
        this.model.addHandler('*', this.validateCreation);

    }

    searchParticipant = (query: any) => this.getAvailableParticipants(query);

    getAvailableParticipants = (query: string) => {
        function filterSelected(meeting: any, participants: any) {
            const selectedIds = meeting.participants.map(mapId);

            return participants.filter((participant: any) => !includes(selectedIds, participant.id));
        }

        let promises = [
            this.Entity.get('employee')
                .search({
                    'name': query,
                    'join': ['photo', 'specialisations'],
                    'specialisations.type': 'manager',
                    'customers.id': this.saleEvent.customer && this.saleEvent.customer.id || []
                })
        ];

        if (this.saleEvent.customer) {
            promises.push(this.Entity.get('customer_employee').search({
                'customer.id': this.saleEvent.customer.id,
                'name': query,
                'join': ['photo']
            }));
            promises.push(this.Entity.get('agent').search({ 'customers.id': this.saleEvent.customer.id, 'name': query, 'join': ['photo'] }));
        }

        return Promise.all(promises)
            .then((values: any) => {

                let persons: any = [];

                if (values[1]) {
                    values[1].forEach((item: any) => item.name = `${item.name} - ${this.saleEvent.customer.alias}`);
                }

                values.forEach((p: any) => persons = persons.concat(p));

                return sortBy(filterSelected(this.saleEvent, persons), 'name');

            });
    };

    create() {
        let data = prepareRequest(this.saleEvent);

        data.participants = safeMapId(data.participants);

        this.Entity.get('sale_event')
            .create(data)
            .then((response: any) => {
                if (!this.saleOpportunity && !this.customer) this.PromanState.to('SaleEvent', response);
                return this.dialogRef.close(response);
            });

    }

    validateCreation = () => {
        const item = this.saleEvent;

        this.canCreate = !!(item.name && item.saleEventType && item.date);
    };

    handleOnlineEvent(type: string) {
        const saleEvent = this.saleEvent;
        saleEvent.summary = 'Gapi';
        saleEvent.location = 'Kalvarijų g. 1, Vilnius 09310';

        const method =
            type === 'meet' ? 'meetAuthorize' :
                type === 'zoom' ? 'zoomAuthorize' :
                    type === 'voov' ? 'createTencentEvent' : null;

        let start = saleEvent.date ? new Date(saleEvent.date) : new Date();
        let end = new Date(start);
        end.setSeconds(start.getSeconds() + (saleEvent.duration ? saleEvent.duration : 60 * 20));

        const eventInformation: {
            summary: string;
            location: string;
            goals: string;
            topic: string;
            description: string;
            personId: number;
            start: string;
            end: string;
            duration: number;
            participantsId: number[];
            comments: string;
            colorId: number;
        } = {
            summary: saleEvent.summary,
            location: saleEvent.location,
            goals: saleEvent.goals,
            topic: saleEvent.name,
            description: saleEvent.resume,
            personId: this.currUser.id,
            start: start.toISOString(),
            end: end.toISOString(),
            duration: saleEvent.duration,
            participantsId: safeMapId(this.saleEvent.participants),
            comments: saleEvent.summary,
            colorId: 1
        };

        // const upComingEvents: {
        //     'calendarId': string;
        //     'timeMin': string;
        //     'showDeleted': boolean;
        //     'singleEvents': boolean;
        //     'maxResults': number;
        //     'orderBy': string;
        // } = {
        //     calendarId: 'primary',
        //     timeMin: (new Date()).toISOString(),
        //     showDeleted: false,
        //     singleEvents: true,
        //     maxResults: 10,
        //     orderBy: 'startTime'
        // }
        //
        // if (type === 'meet') {
        //     this.Gapi.listUpcomingEvents(upComingEvents)
        //         .then(() => this.Gapi.createCalendarEvents(eventInformation));
        //     return;
        // }

        // Backend request
        (this.Entity.get('create_online_meeting') as CreateOnlineMeetingInterface)
            [method](eventInformation).then((url:string) => window.open(url, '_blank'));
    }
}
